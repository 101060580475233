* {
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        color: blue;
        text-decoration: underline;
    }
    /* ... */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
} */

#root {
    height: 100%;
}

#scrolling-ideas {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: scroll-animation 10s linear infinite;
    -webkit-animation: scroll-animation 10s linear infinite;
    animation: scroll-animation 10s linear infinite;
}

/* for Chrome */
@-webkit-keyframes scroll-animation {
    from {
        -webkit-transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-animation {
    from {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.tag-list {
    width: 60rem;
    max-width: 90vw;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 3rem 0;
    overflow: hidden;
}

.loop-slider {
    .inner {
        display: flex;
        width: fit-content;
        animation-name: loop;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: var(--direction);
        animation-duration: var(--duration);
    }
}

.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.2rem;
    color: #334155;
    font-size: 0.9rem;
    background-color: #e2e8f0;
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    /*
   box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 20%), 0 0.1rem 0.5rem rgb(0 0 0 / 30%), 0 0.2rem 1.5rem rg b(0 0 0 / 40%);
   */
    overflow: hidden;
    white-space: nowrap;
    span {
        font-size: 1.2rem;
        color: #64748b;
        overflow: hidden;
        white-space: nowrap;
    }
}

.fade {
    pointer-events: none;
    /*  background: linear-gradient(95deg, #ced0d3, transparent 10%, transparent 90%, #ced0d3); */
    background: linear-gradient(90deg, #ffffff, transparent 10%, transparent 90%, #ffffff);
    position: absolute;
    inset: 0;
}

@keyframes loop {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
